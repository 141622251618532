import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { deleteUserStarted, deleteUserCompleted } from "../state/actions";
import { selectUser } from "src/app/user";

@Injectable({ providedIn: 'root' })
export class CommandDeleteUser {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(userId: number): Promise<void> {
        const subscription = this.store.select(selectUser).subscribe(async user => {
            this.store.dispatch(deleteUserStarted());
            await this.assignAdminTask(userId);
            this.store.dispatch(deleteUserCompleted());
        });

        subscription.unsubscribe();
    }

    private async assignAdminTask(userId: number): Promise<void> {
        await this.http
            .post<void>(environment.apiUrl + "/api/admin/delete-user", { userId })
            .toPromise();
    }
}